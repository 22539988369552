@use '../../../styles/common';

.optionList {
  .header {
    background: #1d1e20;
    border-bottom: 1px solid #292a2e;
  }

  .header {
    padding: 16px 24px;
    display: flex;

    .titleBlock {
      display: flex;
      gap: 8px;
      margin-bottom: 8px;

      .title {
        @include common.mn-font(white, 20px, 600, 30px);
      }

      .optionCount {
        @include common.mn-font(white, 14px, 500, 20px);
        background: rgba(255, 255, 255, 0.15);
        border-radius: 13px;
        padding: 3px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .description {
      @include common.mn-font(#cacbce, 14px, 400, 20px);
    }
  }

  thead {
    background: #1d1e20;

    tr {
      th {
        padding: 17px;
        @include common.mn-font(white, 12px, 600, 18px);
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 17px;
        @include common.mn-font(white, 14px, 500, 20px);

        &.disabled {
          opacity: 0.5;
        }

        .nameBlock {
          display: flex;
          gap: 12px;
          align-items: center;

          .image {
            height: 40px;
            width: 40px;
          }

          .name {
          }

          .status {
            @include common.mn-font(#cacbce, 14px, 400, 20px);
          }
        }

        .type {
          display: flex;
        }

        .buttons {
          display: flex;
          justify-content: space-between;
          gap: 16px;

          .editButton {
          }
        }
      }
    }
  }

  .noItems {
    padding: 32px 49px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
