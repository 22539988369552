@use 'src/styles/common';

.target {
  display: flex;
  align-items: center;
  justify-content: center;
}

.burger {
  color: common.fn-color(white);
  height: 36px;
  width: 36px;
}

.avatar {
  height: 40px !important;
  width: 40px !important;
  border-radius: 50%;
  cursor: pointer;
}

.label {
  margin: 4px;
  background: transparent !important;
  //justify-content: center;
  padding: 6px !important;
  pointer-events: none;
}
