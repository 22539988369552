@use '../../../../../styles/common';

.optionPreview {
  background: #1d1e20;
  border: 1px solid #292a2e;
  border-radius: 16px;
  padding: 8px;
  display: flex;
  gap: 20px;
  height: 100%;
  width: 100%;

  img {
    height: 175px;
    width: 175px;
    border-radius: 8px;
  }

  .info {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 30px;
    justify-content: space-between;
    padding-bottom: 8px;

    .upperContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .name {
        @include common.mn-font(white, 20px, 700, 30px);
      }

      .description {
        @include common.mn-font(white, 12px, 500, 18px);
      }
    }

    .lowerContainer {
      display: flex;
      align-items: flex-end;

      .stats {
        flex: 1;
        display: flex;
        gap: 20px;

        .stat {
          display: flex;
          flex-direction: column;
          min-width: 100px;

          .name {
            @include common.mn-font(#818181, 14px, 400, 20px);
          }

          .value {
            @include common.mn-font(white, 16px, 700, 24px);
          }
        }
      }

      .buttons {
        min-width: 150px;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }

  @media (max-width: common.$var-breakpoint-md) {
    flex-direction: column;
    padding: 8px;

    img {
      width: 100%;
      height: auto;
    }

    .info {
      .upperContainer {
        padding: 0 8px;

        .name {
          @include common.mn-font(white, 14px, 700, 20px);
        }

        .description {
          color: #cccccc;
        }
      }

      .lowerContainer {
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        gap: 24px;
        flex: 1;

        .stats {
          padding: 0 8px;
          flex-direction: column;
          justify-content: flex-end;
          width: 100%;
          gap: 24px;

          .stat {
            flex-direction: row;
            justify-content: space-between;
          }
        }

        .buttons {
          width: 100%;
          height: 90px;
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
        }
      }
    }
  }
}
