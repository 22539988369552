@use 'src/styles/common';

.loading {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;

  .shimmer {
    color: grey;
    display: inline-block;
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
    font-size: 50px;
    max-width: 200px;
  }

  @keyframes shimmer {
    100% {
      -webkit-mask-position: left;
    }
  }

  .text {
    @include common.mn-font(#818181, 16px, 500, 24px);
  }
}
