@use 'src/styles/common';

.iconPicker {
  display: flex;
  gap: 12px;

  .dropdown {
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .label {
        @include common.mn-font(white, 20px, 700, 30px);
      }
    }

    .icons {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(5, 1fr);
      margin-bottom: 32px;

      .iconContainer {
        cursor: pointer;
        height: 44px;
        width: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;

        &.activeIcon {
          border: 2px solid #ffffff;
        }

        .icon {
          height: 30px;
          width: 30px;
        }
      }
    }

    .colorsLabel {
      @include common.mn-font(white, 14px, 600, 20px);
      margin-bottom: 16px;
    }

    .colors {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(8, 1fr);
      margin-bottom: 32px;

      .color {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .colorBorder {
          height: 28px;
          width: 28px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border: 2px solid white;
        }

        .iconCheck path {
          stroke: white;
        }
      }
    }
  }
}
