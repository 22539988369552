@use '../../../styles/common';

.addDescription {
  max-width: 745px;

  .fieldDescription {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    @include common.mn-font(#cacbce, 12px, 500, 18px); // :TODO
  }
}
