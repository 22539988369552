@use '../../../../styles/common';

.filmGallery {
  width: 100%;
  padding: 26px;
  border-radius: 30px;
  border: 1px solid common.fn-color(dark, 6);
  position: relative;

  .control {
    position: absolute;
    top: 50%;
    z-index: 1;
    display: flex;
    width: 32px;
    height: 54px;
    justify-content: center;
    align-items: center;
    background: #292a2e;
    border-radius: 6px;
    color: white;

    &.left {
      left: 0;
      transform: translate(10px, -50%);
    }

    &.right {
      right: 0;
      transform: translate(-10px, -50%);
    }
  }

  .carousel {
    border-radius: 12px;
  }

  .slide {
    cursor: pointer;
  }

  .image {
    border-radius: 12px;
    object-fit: cover;
    height: 186px;
    width: 186px;
  }
}
