@use 'src/styles/common';

.uploadVideos {
  max-width: 745px;

  .withMargin {
    margin-bottom: 24px;
  }

  .fieldDescription {
    @include common.mn-font(#cacbce, 12px, 500, 18px); // :TODO
    margin-bottom: 16px;
  }
}
