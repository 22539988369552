@use '../../styles/common';

.contractDetails {
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  border: 1px solid common.fn-color(dark, 6);
  padding: 20px;
  gap: 16px;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 0 3px;

    &:not(:last-child) {
      border-bottom: 1px solid #292a2e;
    }

    .name {
      @include common.mn-font(#cacbce, 14px, 400, 20px);
    }

    .value {
      @include common.mn-font(white, 16px, 600, 24px);
    }
  }
}
