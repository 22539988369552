@use '../../../../styles/common';

.collectionDetails {
  padding-top: 48px;
  gap: 32px;
  display: flex;
  container-name: details;
  container-type: inline-size;

  .sections {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 48px;

    .collectionBrief {
      border-radius: 30px;
      border: 1px solid common.fn-color(dark, 6);
      display: flex;
      flex-direction: column;

      .spoilerContent {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .creator {
        align-self: flex-start;
      }

      .description {
        @include common.mn-font(#cacbce, 16px, 400, 24px);
      }
    }
  }

  //@container details (max-width: #{common.$var-breakpoint-md}) {
  //  .navigation {
  //    display: none;
  //  }
  //}

  @media (max-width: common.$var-container-width) {
    .navigation {
      display: none;
    }
  }
}
