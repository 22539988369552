@use '../../../styles/common';

.navItems {
  display: flex;
  flex-direction: column;
  gap: 14px;

  .navItem {
    min-width: 160px;
    white-space: nowrap;
    @include common.mn-font(#818181, 14px, 400, 20px);
    text-decoration: none;

    &.active {
      @include common.mn-font(white, 14px, 700, 20px);
    }

    &:hover {
      font-weight: 900;
    }
  }
}
