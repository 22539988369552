@use '../../../styles/common';

.section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  container-type: inline-size;
  container-name: section;

  > div:last-child {
    //margin-top: 12px;
  }

  &:not(:last-of-type) {
    //margin-bottom: 48px;
  }

  .sectionName {
    margin: 0 0 0 24px;
    @include common.mn-font(white, 30px, 900, 38px, -0.015em);
    display: flex;
    justify-content: space-between;

    .buttons {
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }

  .sectionDescription {
    margin: 0 24px;
    @include common.mn-font(#cacbce, 20px, 400, 30px);
  }

  @container section (max-width: calc(#{common.$var-breakpoint-sm} + 100px)) {
    .sectionName {
      gap: 12px;
      flex-direction: column;
    }
  }

  @container section (max-width: #{common.$var-breakpoint-md}) {
  }
}
