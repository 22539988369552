@use '../../../styles/common';

.iconPreview {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
}
