@use 'src/styles/common';

.getNotifiedModal {
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 0 32px 32px;

  .loader {
    align-self: center;
  }

  .row {
    display: flex;
    gap: 16px;

    div:first-child {
      flex: 1;
    }

    .button {
      margin-right: 4px;
    }
  }

  .fieldDescription {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    @include common.mn-font(#cacbce, 12px, 500, 18px); // :TODO
  }
}
