.fileView {
  display: flex;
  padding: 20px;
  border: 1px solid #303136;
  border-radius: 12px;
  align-items: flex-start;
  gap: 14px;
  background: #1d1e20;

  .iconTrash {
    path {
      fill: #f15050;
    }
  }

  .iconFileContainer {
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(314.99deg, rgba(#a0d52f, 0.15) 1.99%, rgba(#ddfb6e, 0.15) 98%);
  }

  &.error {
    background: rgba(241, 80, 80, 0.15);
    border: 1px solid #602424;

    .iconFileContainer {
      background: rgba(241, 80, 80, 0.15);
    }

    .iconFile {
      path {
        fill: #f15050;
      }
    }

    .container {
      .upperBlock .fileInfo {
        .status,
        .fileSize {
          color: #f15050;
          font-weight: 900;
        }

        .fileName {
          color: #d84747;
        }
      }

      .progress {
        display: none;
      }
    }
  }

  &.done {
    .iconFileContainer {
      background: linear-gradient(314.99deg, #a0d52f 1.99%, #ddfb6e 98%);
    }

    .status {
      color: #d3f85a;
    }
  }

  .container {
    flex: 1;
    display: flex;
    flex-direction: column;

    .upperBlock {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .fileInfo {
        display: flex;
        flex-direction: column;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: white;

        .fileName {
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .fileSize {
          color: #cacbce;
        }
      }
    }

    .progress {
      display: flex;
      gap: 12px;
      align-items: center;
      color: white;

      .bar {
        flex: 1;
      }
    }
  }
}
