@use 'src/styles/common';

.header {
  display: flex;
  padding: 12px 32px;
  align-items: center;
  width: 100%;
  gap: 32px;

  &.border {
    border-bottom: 1px solid #303136;
  }

  .links {
    flex: 1;
  }

  @media (min-width: common.$var-breakpoint-sm) {
    .menuHidden {
      display: none;
    }
  }

  @media (max-width: common.$var-breakpoint-sm) {
    gap: 16px;

    .links {
      > * {
        display: none;
      }
    }
  }
}
