@use '../../styles/common';

.goalForm {
  max-width: 745px;

  .fieldDescription {
    margin-top: 4px;
    @include common.mn-font(#cacbce, 12px, 500, 18px); // :TODO
  }
}
