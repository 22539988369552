@use '../../styles/common';

.turnOffWaitlist {
  max-width: 745px;

  .row {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-end;

    > div:first-child {
      align-self: stretch;
    }
  }
}
