@use 'src/styles/common';

.appIconButton {
  background: common.fn-color(dark, 6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  gap: 10px;
  height: 44px;
  border-radius: 12px;
  white-space: nowrap;

  &.round {
    border-radius: 24px;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.reversed {
    flex-direction: row-reverse;
  }

  @include common.mn-font(white, 16px, 900, 100%);

  .icon {
    height: 20px;
    width: 20px;
  }

  &.white {
    background: common.fn-color(white);
    color: black;
    height: 40px;

    svg path {
      fill: black;
    }
  }

  &.outline,
  &.icon {
    background: transparent;

    &.border {
      border: 1px solid #292a2e;
    }
  }

  &.icon {
    padding: 0;
    height: unset;
  }
}
