@use 'src/styles/common';

.contentModal {
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 0 32px 32px;

  .loader {
    align-self: center;
  }

  .description {
    @include common.mn-font(white, 12px, 500, 18px);
  }
}
