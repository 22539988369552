@use 'src/styles/common';

.addBonuses {
  max-width: 745px;

  .fieldDescription {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    @include common.mn-font(#cacbce, 12px, 500, 18px); // :TODO
  }

  .bonusesForm form {
    min-height: unset;
    justify-content: flex-start;
    padding: 0;
    overflow: hidden;
  }
}
