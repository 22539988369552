@use 'src/styles/common';

.links {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 32px;

  .link {
    position: relative;
    display: block;
    text-decoration: none;
    @include common.mn-font(white, 16px, 700, 150%);

    &.active {
      &:after {
        background: common.fn-color(white);
        position: absolute;
        top: 26px;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }
  }
}
