@use '../../../styles/common';

.collection {
  flex: 1;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  z-index: 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    gap: 48px;
    padding: 48px 32px 68px 48px;
    position: relative;
    align-self: center;
    width: 100%;
    max-width: common.$var-container-width;

    .collectionDetails {
      position: relative;
      overflow: hidden;
      flex: 1;

      .buttonEdit {
        position: absolute;
        right: 0;
      }
    }

    .minter {
      position: sticky;
      top: 24px;
    }
  }

  @media (max-width: common.$var-breakpoint-xxl) {
    .content {
      flex-direction: column-reverse;

      .minter {
        position: static;
        width: unset;
      }
    }
  }

  @media (max-width: common.$var-breakpoint-lg) {
    .content {
      padding: 0;
      gap: 20px;

      .collectionDetails {
        display: flex;
        flex-direction: column;

        .buttonEdit {
          position: static;
          margin: 0 12px;
        }
      }

      .minter {
        margin: 0 12px;
      }
    }
  }
}
