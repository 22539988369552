@use '../../../../styles/common';

.collectionDetails {
  padding-top: 48px;
  gap: 32px;
  display: flex;
  width: 100%;

  .sections {
    max-width: 736px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 48px;

    .storyContainer {
      display: flex;
      flex-direction: column;
      padding: 20px;
      border: 1px solid #292a2e;
      border-radius: 28px;
      gap: 24px;
      align-items: flex-start;
    }
  }

  @media (max-width: common.$var-breakpoint-lg) {
    .navs {
      display: none;
    }

    .sections {
      max-width: unset;
    }
  }

  @media (min-width: common.$var-breakpoint-xxl) {
  }
}
