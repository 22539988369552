@use '../../../styles/common';

.collection {
  flex: 1;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  z-index: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 70px;

  .content {
    display: flex;
    gap: 48px;
    padding: 0 42px 12px;
    position: relative;
    align-self: center;
    max-width: common.$var-container-width;
    width: 100%;

    .collectionDetails {
      position: relative;
      overflow: hidden;
      width: 100%;
      display: flex;
      flex-direction: column;

      .buttonEdit {
        position: absolute;
        right: 0;
      }
    }
  }

  @media (max-width: common.$var-breakpoint-md) {
    gap: 20px;

    .content {
      padding: 0 0 12px;

      .collectionDetails {
        .buttonEdit {
          position: static;
          margin: 0 12px;
        }
      }
    }
  }

  @media (min-width: common.$var-breakpoint-md) {
    padding-bottom: 84px;
  }

  @media (max-width: common.$var-breakpoint-lg) {
  }

  @media (min-width: common.$var-breakpoint-xxl) {
    .content {
      padding: 0 84px 12px;
    }
  }
}
