@use 'src/styles/common';

.collectionStatistics {
  display: flex;
  background: #292a2e;
  border: 1px solid rgba(59, 60, 63, 0.5);
  border-radius: 16px;
  padding: 8px 16px;
  justify-content: space-between;

  .item {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .name {
      @include common.mn-font(#818181, 12px, 500, 18px);
    }

    .value {
      @include common.mn-font(white, 16px, 700, 24px);
    }
  }

  &.withSeparator {
    .item {
      gap: 2px;
      padding: 0 24px;
      justify-content: center;
      align-items: center;

      &:not(:last-child) {
        border-right: 1px solid #3b3c3f;
      }
    }
  }
}
