@use 'src/styles/common';

.overlappingCards {
  container-type: inline-size;
  container-name: cards;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;

  input {
    position: absolute;
    width: 1px;
    clip: rect(0 0 0 0);
    overflow: hidden;
    white-space: nowrap;
  }

  .selector {
    display: flex;
    align-items: center;
  }

  .poster,
  .nft {
    cursor: pointer;
    position: relative; /* for iOS */
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 10px 20px #0006;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out; /* important to use a symmetrical timing function (linear also works) */
    animation-fill-mode: forwards;

    img {
      height: 100%;
      width: 100%;
    }
  }

  @container cards (max-width: #{common.$var-breakpoint-md}) {
    .selector {
      min-height: 400px;
      position: relative;
      display: flex;
      justify-content: center;
    }

    .poster {
      width: 212px;
      height: 318px;
      z-index: 1;
      position: absolute;
    }

    .nft {
      width: 212px;
      height: 212px;
      position: absolute;
    }

    #nft:checked ~ .selector .nft {
      animation-name: swing-forwards-nft;
    }

    #poster:checked ~ .selector .poster {
      animation-name: swing-forwards-poster;
    }

    #nft:checked ~ .selector .poster {
      animation-name: swing-backwards-poster;
    }

    #poster:checked ~ .selector .nft {
      animation-name: swing-backwards-nft;
    }

    @keyframes swing-forwards-nft {
      0% {
        z-index: 1;
        transform: translateY(-100px) scale(1);
      }
      50% {
        opacity: 0.5;
      }
      100% {
        z-index: 2;
        transform: translateY(0) scale(1.1);
      }
    }

    @keyframes swing-backwards-nft {
      0% {
        z-index: 2;
        transform: scale(1.1);
      }
      50% {
        opacity: 0.5;
      }
      100% {
        z-index: 1;
        transform: translateY(-100px) scale(1);
      }
    }

    @keyframes swing-forwards-poster {
      0% {
        z-index: 1;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        z-index: 2;
        transform: scale(1.1);
      }
    }

    @keyframes swing-backwards-poster {
      0% {
        z-index: 2;
        transform: scale(1.1);
      }
      50% {
        opacity: 0.5;
      }
      100% {
        z-index: 1;
        transform: scale(1);
      }
    }
  }

  @container cards (min-width: #{common.$var-breakpoint-md}) {
    .poster {
      margin-right: -85px;
      --swing: -100px;
      --overlap: 25px;
      width: 320px;
      height: 480px;
    }

    .nft {
      margin-left: -85px;
      --swing: 100px;
      --overlap: -25px;
      width: 320px;
      height: 320px;
    }

    #poster:checked ~ .selector .poster {
      animation-name: swing-forwards;
    }

    #nft:checked ~ .selector .nft {
      animation-name: swing-forwards;
    }

    #nft:checked ~ .selector .poster {
      animation-name: swing-backwards;
    }

    #poster:checked ~ .selector .nft {
      animation-name: swing-backwards;
    }

    @keyframes swing-forwards {
      0% {
        z-index: 1;
        transform: scale(0.8);
      }
      50% {
        transform: translateX(var(--swing));
      }
      100% {
        z-index: 2;
        transform: translateX(var(--overlap)) scale(1);
      }
    }

    @keyframes swing-backwards {
      0% {
        z-index: 2;
        transform: translateX(var(--overlap)) scale(1);
      }
      50% {
        transform: translateX(var(--swing));
      }
      100% {
        z-index: 1;
        transform: scale(0.8);
      }
    }
  }
}
