@use '../../../../styles/common';

.filmDetails {
  padding-top: 48px;
  gap: 32px;
  display: flex;

  .sections {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 48px;

    .mediaGallery {
      padding: 24px 20px;
      border: 1px solid #292a2e;
      border-radius: 28px;
    }
  }

  @media (max-width: common.$var-container-width) {
    .navigation {
      display: none;
    }
  }
}
