@use 'src/styles/common';

.imageModal {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .image {
    border-radius: 12px;
    height: 80vh;
  }
}
