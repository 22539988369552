@use 'src/styles/common';

.rteLinkModal {
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 0 32px 32px;

  .button {
    align-self: flex-end;
  }
}
