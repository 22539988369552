@use 'src/styles/common';

.goalCalculator {
  padding: 24px;
  border: 1px solid #292a2e;
  border-radius: 28px;
  display: flex;
  flex-direction: column;
  width: 336px;
  align-self: flex-start;
  gap: 32px;

  .upperBlock,
  .lowerBlock {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .title {
    @include common.mn-font(white, 14px, 600, 20px);
  }

  .value {
    @include common.mn-font(white, 30px, 600, 38px);

    &.red {
      color: #f15050;
    }

    &.green {
      color: common.fn-color(lime);
    }
  }

  .info {
    margin-top: 8px;
    @include common.mn-font(#cacbce, 12px, 400, 18px);
  }
}
