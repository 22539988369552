@use 'src/styles/common';

.userFanCollections {
  container-type: inline-size;
  container-name: collections;
  $cols: 4;
  $gap: 20px;

  .items {
    display: flex;
    flex-wrap: wrap;
    gap: $gap;

    .item {
      flex-basis: common.fn-basis($cols, $gap);
      border-radius: 12px;
      aspect-ratio: 11/16;
    }
  }

  .noItems {
    margin: 0 auto;
  }
}


@container collections  (max-width: 1100px) {
  $cols: 3;
  $gap: 10px;

  .userFanCollections {
    .items {
      gap: $gap;

      .item {
        flex-basis: common.fn-basis($cols, $gap);
      }
    }
  }
}

@container collections  (max-width: 900px) {
  $cols: 2;
  $gap: 10px;

  .userFanCollections {
    .items {
      gap: $gap;

      .item {
        flex-basis: common.fn-basis($cols, $gap);
      }
    }
  }
}
