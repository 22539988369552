@use 'src/styles/common';

.footer {
  border-top: 1px solid #292b2e;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 378px;
  justify-content: space-between;
  padding: 4%;
  overflow: hidden;
  gap: 20px;

  .image {
    //filter: brightness(5);
    position: absolute;
    width: auto;
  }

  .upperContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;

    .brandContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .brand {
        display: flex;
        align-items: center;
        gap: 10px;

        .logo {
          height: 40px;
          width: 40px;
        }

        @include common.mn-font(white, 20px, 600, 20px);
      }

      .address {
        @include common.mn-font(#cececf, 16px, 400, 26px);
      }
    }

    .links {
      z-index: 1;
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 15px;

      .section {
        .label {
          @include common.mn-font(white, 18px, 600, 20px);
          margin-bottom: 10px;
        }

        .link {
          cursor: pointer;
          @include common.mn-font(#cececf, 16px, 400, 26px);
        }
      }
    }
  }

  .lowerContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;

    .copyright {
      @include common.mn-font(#cececf, 16px, 400, 26px);
    }

    .socials {
      display: flex;
      gap: 10px;

      .social {
        height: 25px;
        width: 25px;
      }
    }
  }
}

@media (max-width: common.$var-breakpoint-lg) {
  .footer {
    .upperContainer {
      flex-direction: column;
      margin-bottom: 30px;

      .brandContainer {
        margin-bottom: 30px;
      }

      .links {
        justify-content: space-between;
      }
    }

    .lowerContainer {
      flex-direction: column-reverse;
    }
  }
}

@media (max-width: common.$var-breakpoint-sm) {
  .footer {
    .upperContainer {
      .links {
        .section {
          .label {
            font-size: 14px;
          }

          .link {
            font-size: 12px;
          }
        }
      }

      .brandContainer {
        .brand {
          font-size: 16px;
        }

        .address {
          font-size: 12px;
        }
      }
    }

    .lowerContainer {
      .copyright {
        font-size: 12px;
      }
    }
  }
}
