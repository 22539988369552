@use "src/styles/common";

.container {
  display: flex;
  flex-wrap: wrap;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  position: absolute;
  background: var(--mantine-color-red-8);
  z-index: 10;
  width: 100%;
  max-width: 100%;

  .link {
    color: #fff;
  }

  .title {
    padding: 10px;
    color: #fff;
  }

  .inner {
    position: relative;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .button {
    display: inline-block;
    padding: 10px;
    cursor: pointer;
  }
}
