@use 'src/styles/common';

.brand {
  cursor: pointer;

  &.primary path {
    fill: common.fn-color(primary);
  }

  &.white path {
    fill: common.fn-color(white);
  }

  &.black path {
    fill: common.fn-color(black);
  }

  &.gradient {
    background: linear-gradient(314.99deg, #a0d52f 1.99%, #ddfb6e 98%), #cccccc;
    border-radius: 12px;

    path {
      fill: black;
    }
  }
}
