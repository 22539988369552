.appAvatar {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  background: #1d1e20;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
  }

  svg {
    height: 70%;
    width: 70%;
  }
}
