@use '../../../styles/common';

.bonusList {
  .header,
  .tableHeader {
    background: #1d1e20;
    border-bottom: 1px solid #292a2e;
  }

  .header {
    padding: 16px 24px;
    display: flex;

    .titleBlock {
      display: flex;
      gap: 8px;
      margin-bottom: 8px;

      .title {
        @include common.mn-font(white, 20px, 600, 30px);
      }

      .bonusCount {
        @include common.mn-font(white, 14px, 500, 20px);
        background: rgba(255, 255, 255, 0.15);
        border-radius: 13px;
        padding: 3px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .description {
      @include common.mn-font(#cacbce, 14px, 400, 20px);
    }
  }

  .tableHeader {
    display: flex;

    > div {
      padding: 16px;
      @include common.mn-font(white, 12px, 800, 18px);

      &:nth-child(1) {
        flex: 2.5;
      }

      &:nth-child(2) {
        flex: 1.5;
      }

      &:nth-child(3) {
        flex: 0.5;
      }
    }
  }

  .bonuses > :not(:last-child) {
    border-bottom: 1px solid #292a2e;
  }
}
