@use "src/styles/common";

.form {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: common.fn-color(primary);

  .formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 670px;
    padding: 30px 40px;
    border-radius: 40px;
    background: common.fn-color(white);

    .title {
      margin: 30px;
      @include common.mn-font(black, 48px, 700, 110%, 0.03em);
      text-align: center;
    }
  }
}
