@use 'src/styles/common';

.importImdb {
  display: flex;
  align-items: flex-end;
  gap: 48px;

  .row {
    display: flex;
    gap: 16px;

    div:first-child {
      flex: 1;
    }

    .button {
      margin-right: 4px;
    }
  }

  .previewContainer {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: 336px;
    height: 468px;
    border: 1px solid #292a2e;
    border-radius: 28px;
    position: relative;

    .previewLabel {
      @include common.mn-font(white, 14px, 700, 20px);
      align-self: flex-start;
    }

    .imagePlaceholder,
    .image {
      height: 300px;
      width: 200px;
      border: 1px solid #303136;
      border-radius: 8px;
    }

    .imagePlaceholder {
      background: linear-gradient(0deg, #1d1e20, #1d1e20);
      display: flex;
      justify-content: center;
      align-items: center;

      .previewPlaceholder {
        @include common.mn-font(#818181, 16px, 500, 24px);
        text-align: center;
      }
    }

    .namePlaceholder {
      width: 132px;
      height: 30px;
      background: rgba(29, 30, 32, 0.84);
      border-radius: 8px;
    }

    .name {
      @include common.mn-font(white, 20px, 900, 30px);
      text-align: center;
    }
  }
}
