@use '../../styles/common';

.optionsForm {
  max-width: 1080px;

  .fieldDescription {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    @include common.mn-font(#cacbce, 12px, 500, 18px); // :TODO
  }

  .container {
    display: flex;
    gap: 36px;
    position: relative;
  }

  .optionsForm form {
    min-height: unset;
    justify-content: flex-start;
    padding: 0;
    overflow: hidden;
  }
}

.loader {
  height: 100% !important;
}

@media (max-width: calc(#{common.$var-breakpoint-xxl} + 300px)) {
  .goalBlock {
    border: none;
    flex-direction: row;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: -30px;
    justify-content: flex-end;

    > div:last-child {
      > div:last-child {
        display: none;
      }
    }
  }

  .optionsForm {
    .container {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
  }
}
