@use "src/styles/common";

.requests {
  padding: 60px 0;

  .title {
    @include common.mn-font(primary, 30px, 900, 110%, 0.03em);
    margin-bottom: 20px;
  }

  .subtitle {
    @include common.mn-font(primary, 20px, 700, 120%, 0.02em);
    padding-bottom: 10px;
    border-bottom: 1px solid common.fn-color(primary);
  }

  .content {
    display: flex;
    align-items: flex-start;
    padding: 20px 0;
    gap: 45px;

    .leftBlock {
      flex: 1;

      .search {
        margin-bottom: 30px;
      }

      .table {
        th {
          @include common.mn-font(white, 12px, 600, 150%, 0.001em);
        }

        .row {
          height: 50px;
          cursor: pointer;
          @include common.mn-font(white, 12px, 400, 150%);

          &.selected {
            color: common.fn-color(primary);
            border-left: 2px solid common.fn-color(primary);
          }
        }
      }
    }

    .skeleton {
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include common.mn-font(gray, 18px, 400, 120%, 0.002em, 6);
      background: #17191f;
      height: 300px;
      width: 440px;
    }
  }
}
