@use 'src/styles/common';

.creatorLabel {
  display: flex;
  align-items: center;
  padding: 6px 16px 6px 6px;
  gap: 10px;
  background: #292a2e;
  border-radius: 28px;
  cursor: pointer;

  .avatar {
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }

  .name {
    @include common.mn-font(white, 16px, 600, 24px);
  }

  &.small {
    gap: 6px;

    .name {
      @include common.mn-font(white, 12px, 500, 18px);
    }

    .iconVerified {
      height: 14px;
      width: 14px;
    }
  }
}
