.volume {
  position: absolute;
  width: 32px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  padding: 10px 6px;
  gap: 5px;
  min-height: 50px;

  &:not(.expanded) {
    top: 50%;
    transform: translateY(-50%);
  }

  &.expanded {
    background: #1d1e20;
    border: 1px solid #303136;
    bottom: -9px;
  }

  .icon,
  .buttonVolume {
    cursor: pointer;
  }

  .icon {
    height: 20px;
    width: 20px;
  }

  .barContainer {
    display: flex;
    min-height: 62px;
    height: 62px;
    width: 4px;
    position: relative;
    align-items: flex-end;
    background: #686868;
    border-radius: 23px;
    overflow: hidden;

    .bar {
      width: 100%;
      background: #ffffff;
    }

    .buttonVolume {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
}
