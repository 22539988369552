.loaderContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  flex: 1;

  > *:first-of-type {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
