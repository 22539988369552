@use 'src/styles/common';

.collectionOptions {
  padding: 20px;
  border: 1px solid #292a2e;
  border-radius: 28px;

  .options {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }

  .carousel {
    display: none;
  }

  @media (max-width: calc(#{common.$var-breakpoint-md})) {
    .options {
      display: none;
    }

    .carousel {
      display: block;
    }
  }
}
