@use 'src/styles/common';

.moviePreview {
  height: 100%;
  width: 100%;
  border: 1px solid rgba(59, 60, 63, 0.5);
  border-radius: 16px;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: auto;
    //object-fit: contain;
  }

  .icon {
    display: none;
    height: 72px;
    width: 72px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover .icon {
    display: block;
  }
}
