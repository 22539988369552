@use "src/styles/common";

.link {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 4px;
}

.description {
  @include common.mn-font(black, 18px, 400, 120%, 0.02em);
  margin: 20px 0;
  text-align: center;
}
