/* Small devices (portrait tablets and large phones, 600px and up) */
$breakpoint-sm: 600px;
/* Medium devices (landscape tablets, 768px and up) */
$breakpoint-md: 768px;
/* Large devices (laptops/desktops, 992px and up) */
$breakpoint-lg: 992px;
/* Extra large devices (large laptops and desktops, 1200px and up) */
$breakpoint-xxl: 1200px;

$container-width: 1440px;
