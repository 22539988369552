@use 'src/styles/common';

.userTokens {
  container-type: inline-size;
  container-name: tokens;
  padding-top: 20px;
  $cols: 4;
  $gap: 20px;

  .items {
    display: flex;
    flex-wrap: wrap;
    gap: $gap;
  }

  .item {
    border-radius: 16px;
    height: auto;
    min-height: 280px;
    flex-basis: common.fn-basis($cols, $gap);
  }

  .noItems {
    margin: 0 auto;
  }
}

@container tokens  (max-width: 1000px) {
  $cols: 3;
  $gap: 10px;

  .userTokens {
    .items {
      gap: $gap;

      .item {
        flex-basis: common.fn-basis($cols, $gap);
      }
    }
  }
}

@container tokens  (max-width: 600px) {
  $cols: 2;
  $gap: 10px;

  .userTokens {
    .items {
      gap: $gap;

      .item {
        flex-basis: common.fn-basis($cols, $gap);
      }
    }
  }
}
