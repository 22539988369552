@use 'src/styles/common';

.appPlayerWithPreview {
  position: relative;
  width: 100%;
  padding-top: 50%;
  border-radius: 12px;
  overflow: hidden;
  background: black;

  .buttonPlay,
  .loader,
  .convertingLabel,
  .player {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .convertingLabel {
    @include common.mn-font(#cccccc, 16px, 500, 22px);
  }

  .buttonPlay {
    cursor: pointer;
  }

  img {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
