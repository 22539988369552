@use "src/styles/common";

.stepContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .description {
    @include common.mn-font(black, 18px, 400, 120%, 0.02em);
    margin: 20px 0;
    text-align: center;
  }

  .input {
    width: 100%;
    max-width: 350px;
    margin-bottom: 10px;
  }
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .button {
    flex: 1;
    max-width: 200px;
    margin: 0 10px;
  }
}
