@use '../../styles/common';

.profileForm {
  //min-width: 400px;

  .form form {
    padding: 0;
    position: relative;

    .background {
      width: 100%;
      height: 236px;
    }

    .backgroundButton {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 1;
    }

    .avatarContainer {
      display: flex;
      align-items: flex-end;
      margin-top: -140px;
      margin-bottom: 24px;
      z-index: 1;
      position: relative;
    }

    .avatar {
      height: 200px;
      width: 200px;
    }

    .fields {
      width: 100%;
      padding: 0 188px 32px;
    }
  }

  .withMargin {
    margin-bottom: 24px;
  }

  .row {
    display: flex;
    gap: 20px;

    > div {
      flex: 1;
    }
  }

  .subTitle {
    @include common.mn-font(white, 20px, 600, 30px); // :TODO
  }

  .fieldDescription {
    @include common.mn-font(#cacbce, 12px, 500, 18px); // :TODO
    margin-bottom: 16px;
  }

  .socialInput {
    border-top: 1px solid #292a2e;
    display: flex;
    padding: 20px 0;
    align-items: center;

    .label {
      @include common.mn-font(#cacbce, 14px, 600, 20px);
      min-width: 150px;
    }

    .input {
      flex: 1;
    }
  }

  @media (max-width: common.$var-breakpoint-xxl) {
    .row {
      flex-direction: column;
    }

    .socialInput .label {
      display: none;
    }

    .form form {
      .fields {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }
}
