@use '../../styles/common';

.appTextarea {
  max-width: 745px;
  flex: 1;

  .description {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    @include common.mn-font(#cacbce, 12px, 500, 18px); // :TODO
  }
}
