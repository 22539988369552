@use 'src/styles/common';

.tokenPreview {
  height: 100%;
  width: 100%;
  background: #1d1e20;
  border: 1px solid #292a2e;
  padding: 8px 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  cursor: pointer;

  .tokenInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .price {
      @include common.mn-font(white, 14px, 400, 24px);
    }

    .nameContainer {
      display: flex;
      gap: 4px;
      @include common.mn-font(white, 16px, 700, 24px);

      .collectionName {
      }

      .id {
        color: #cacbce;
        margin-left: 4px;
      }

      .badge {
        @keyframes flickerAnimation {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
          100% {
            opacity: 1;
          }
        }

        margin-left: auto;
        animation: flickerAnimation 1s infinite;
      }
    }
  }
}
