@use 'src/styles/common';

.dropConfigForm {
  max-width: 745px;

  .row {
    display: flex;
    gap: 20px;
    margin-bottom: 24px;

    > div {
      flex: 1;
    }
  }

  .fieldDescription {
    margin-top: 4px;
    @include common.mn-font(#cacbce, 12px, 500, 18px); // :TODO
  }
}
