@use 'src/styles/common';

.bonusContent {
  display: flex;
  gap: 12px;
  background: #1d1e20;
  padding: 14px 16px;
  border: 1px solid #303136;
  border-radius: 12px;

  .iconFileContainer {
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(314.99deg, rgba(#a0d52f, 0.15) 1.99%, rgba(#ddfb6e, 0.15) 98%);
  }

  .file {
    display: flex;
    flex: 1;
    flex-direction: column;

    .name {
      @include common.mn-font(white, 16px, 500, 24px);
      white-space: nowrap;
    }

    .size {
      @include common.mn-font(#cacbce, 14px, 400, 20px);
    }
  }

  .button {
    align-self: center;
  }
}
