@import 'functions';

@mixin font($color, $size, $weight, $line-height, $spacing: '', $shade: 8) {
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $line-height;
  color: color($color, $shade);

  @if ($spacing) {
    letter-spacing: $spacing;
  }
}
