@use 'src/styles/common';

.bonusPreview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  gap: 16px;
  height: 100%;
  container-type: inline-size;
  container-name: bonus;

  .upperContainer {
    display: flex;
    gap: 16px;
    flex-direction: column;

    .name {
      @include common.mn-font(white, 20px, 700, 30px);
    }

    .description {
      @include common.mn-font(#cccccc, 12px, 500, 18px);
    }
  }

  background: #1d1e20;
  border-radius: 16px;

  @container bonus (min-width: #{300px}) {
    .button {
      align-self: flex-end;
      width: 70%;
    }
  }
}
