@use 'src/styles/common';

.imagePreview {
  height: 128px;
  width: 128px;
  flex-shrink: 0;
  background: common.fn-color(#1d1e20);
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  position: relative;
  border: 1px solid #303136;

  .preview {
    width: 100%;
    height: auto;
    z-index: 1;
    object-fit: cover;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }

  img[src=''] {
    display: none;
  }
}
