@use 'src/styles/common';

.bonus {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background: #1d1e20;
  border-radius: 16px;
  overflow: hidden;
  //border: 1px solid common.fn-color(lime);
  container-name: watch-bonus;
  container-type: inline-size;

  .header {
    display: flex;
    gap: 10px;
    align-items: center;

    .iconPlay {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      border-radius: 8px;

      svg path {
        fill: black;
      }
    }

    .name {
      @include common.mn-font(white, 20px, 700, 30px);
    }
  }

  @container watch-bonus (max-width: calc(#{common.$var-breakpoint-md})) {
    .header {
      display: none;
    }
  }
}
