@use 'src/styles/common';

.rte {
  @include common.mn-font(#cacbce, 16px, 400, 20px);

  .description {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    @include common.mn-font(#cacbce, 12px, 500, 18px); // :TODO
  }

  a {
    color: #ddfb6e;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }

  h1 {
    @include common.mn-font(white, 24px, 700, 32px);
    margin: 0;
  }

  h2 {
    @include common.mn-font(white, 20px, 700, 30px);
    margin: 0;
  }
}
