@use 'src/styles/common';

.appCollectors {
  border-radius: 30px;
  border: 1px solid common.fn-color(dark, 6);
  padding: 20px;

  display: flex;
  flex-wrap: wrap;

  .noItems {
    width: 70%;
    margin: auto;
    padding: 20px;
  }

  .cell {
    aspect-ratio: 1 / 1;
    //height: fit-content;

    .skeleton {
      border-radius: 50%;
    }

    &:nth-of-type(1) .collector .collectorInfo .place {
      color: common.fn-color(lime);
    }

    &:nth-of-type(5) .collector .collectorInfo .place {
      color: white;
    }

    .collector {
      height: 100%;
      width: 100%;
      position: relative;
      cursor: pointer;

      .avatar {
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }

      .collectorInfo {
        display: none;
        position: absolute;
        bottom: 0;
        width: 100%;
        align-items: center;
        padding: 8px 5%;
        background: radial-gradient(
            52.55% 139.38% at 0% -0.45%,
            rgba(160, 212, 47, 0.28) 0%,
            rgba(221, 251, 110, 0.125) 34.87%,
            rgba(213, 252, 131, 0.05) 52.99%,
            rgba(170, 219, 58, 0.045) 58.79%,
            rgba(170, 219, 58, 0) 97.53%
          ),
          #1d1e20;
        border-radius: 8px;
        gap: 10px;

        .place {
          font-style: italic;
          font-weight: 900;
          font-size: 38px;
          line-height: 38px;
          color: common.fn-color(lime, 4);
        }

        .nameContainer {
          display: flex;
          flex-direction: column;

          .name {
            @include common.mn-font(white, 12px, 600, 18px);
          }

          .edition {
            @include common.mn-font(#cacbce, 12px, 400, 18px);
          }
        }
      }
    }
  }

  @media (max-width: calc(#{common.$var-breakpoint-md})) {
    .cell {
      &:not(:nth-child(n + 4)) {
        width: calc(100% / 3);
        padding: 10px;

        .collector .collectorInfo {
          display: flex;
        }
      }

      &:nth-child(n + 4) {
        width: calc(100% / 4);
        padding: 6px;
      }

      &:nth-child(n + 8) {
        width: calc(100% / 5);
        padding: 4px;
      }

      &:nth-child(n + 13) {
        display: none;
      }
    }
  }

  @media (min-width: calc(#{common.$var-breakpoint-md})) {
    .cell {
      &:not(:nth-child(n + 4)) {
        width: calc(100% / 3);
        padding: 10px;

        .collector .collectorInfo {
          display: flex;
        }
      }

      &:nth-child(n + 4) {
        width: calc(100% / 8);
        padding: 6px;
      }

      &:nth-child(n + 12) {
        width: calc(100% / 12);
        padding: 4px;
      }

      &:nth-child(n + 12) {
        width: calc(100% / 12);
        padding: 4px;
      }

      &:nth-child(n + 24) {
        display: none;
      }
    }
  }

  @media (max-width: calc(#{common.$var-breakpoint-sm})) {
    .cell .collector .collectorInfo {
      left: 50%;
      transform: translateX(-50%);
      padding: 0;
      bottom: 40%;
      height: 32%;
      width: 32%;
      position: relative;

      .place {
        font-style: italic;
        font-weight: 900;
        font-size: 145%;
        top: 50%;
        left: 45%;
        position: absolute;
        transform: translate(-45%, -50%);
        color: common.fn-color(lime, 4);
      }

      .nameContainer {
        display: none;
      }
    }
  }

  @media (min-width: calc(#{common.$var-breakpoint-xxl})) {
    &.large .cell {
      &:not(:nth-child(n + 6)) {
        width: calc(100% / 5);
        padding: 10px;

        .collector .collectorInfo {
          display: flex;
        }
      }

      &:nth-child(n + 6) {
        width: calc(100% / 8);
        padding: 6px;
      }

      &:nth-child(n + 14) {
        width: calc(100% / 12);
        padding: 4px;
      }

      &:nth-child(n + 24) {
        display: block;
      }
    }
  }
}
