@use 'src/styles/common';

.appFileInput {
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  cursor: pointer;

  .input {
    display: none;

    &[data-disabled] {
      display: flex;
    }
  }

  .clickZone {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed #303136;
    border-radius: 12px;
    padding: 16px 24px;

    .iconFileContainer {
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: linear-gradient(314.99deg, rgba(#a0d52f, 0.15) 1.99%, rgba(#ddfb6e, 0.15) 98%);
      margin-bottom: 12px;
    }

    .innerLabel {
      @include common.mn-font(white, 14px, 400, 20px);
      margin-bottom: 4px;
      display: flex;
      gap: 6px;

      .highlightedLabel {
        @include common.mn-font(#ddfb6e, 14px, 600, 20px);
      }
    }

    .label {
      @include common.mn-font(#cacbce, 12px, 400, 18px);
    }

    &.dragActive {
      background-color: #303136;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .dragZone {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
