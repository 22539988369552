@use 'src/styles/common';

.createCollection {
  display: flex;
  flex-direction: column;
  padding: 128px 64px;
  flex: 1;
  max-width: common.$var-container-width;

  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #2c2c2c; // TODO:
    padding-bottom: 20px;

    .title {
      @include common.mn-font(white, 30px, 900, 38px, -0.03em);
    }

    .buttons {
      display: flex;
      gap: 16px;
    }
  }

  .formContainer {
    width: 732px;

    .withMargin {
      margin-bottom: 26px;
    }

    .row {
      display: flex;
      gap: 20px;

      > div {
        flex: 1;
      }
    }

    .uploader {
      margin-bottom: 6px;
    }

    .imageDescription {
      @include common.mn-font(#cacbce, 14px, 700, 20px);
      margin-bottom: 16px;
    }

    .fieldDescription {
      margin-top: 4px;
      @include common.mn-font(#cacbce, 12px, 500, 18px); // :TODO
    }
  }
}
