@use 'src/styles/common';

.collectionStatistics {
  display: flex;
  background: #292a2e;
  border: 1px solid rgba(59, 60, 63, 0.5);
  border-radius: 16px;
  padding: 16px 0;

  .item {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 0 24px;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
      border-right: 1px solid #3b3c3f;
    }

    .name {
      @include common.mn-font(#818181, 14px, 400, 20px);
    }

    .value {
      @include common.mn-font(white, 20px, 600, 30px);
    }
  }
}
