@use '../../../../styles/common';

.filmOverview {
  border-radius: 30px;
  border: 1px solid common.fn-color(dark, 6);
  container-name: overview;
  container-type: inline-size;

  .topContainer {
    display: flex;
    flex-direction: column;

    .filmName {
      @include common.mn-font(white, 20px, 900, 30px, -0.01em);
      margin-bottom: 8px;
    }

    .yearAndCountry,
    .plot {
      @include common.mn-font(white, 14px, 400, 24px);
      margin-bottom: 20px;
    }

    .keywords {
      display: flex;
      gap: 10px;
      padding-bottom: 64px;
      flex-wrap: wrap;

      .keyword {
        @include common.mn-font(white, 14px, 500, 20px);
        border: 1px solid common.fn-color(dark, 6);
        padding: 6px 12px;
        border-radius: 49px;
      }
    }
  }

  .bottomContainer {
    display: flex;
    gap: 48px;

    .poster {
      width: 240px;
      height: 360px;
      border-radius: 8px;
    }

    .fields {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 24px 0 0;
      gap: 20px;
      flex: 1;

      .field {
        display: flex;
      }

      .fieldName {
        @include common.mn-font(#cacbce, 14px, 400, 20px, '', 4);
        text-transform: uppercase;
        min-width: 150px;
      }

      .fieldValue {
        @include common.mn-font(white, 14px, 600, 20px);
      }
    }
  }

  @container overview (max-width: calc(#{common.$var-breakpoint-sm})) {
    .bottomContainer {
      flex-direction: column;
    }
  }
}
