@use 'src/styles/common';

.appFormContainer {
  display: flex;
  flex-direction: column;
  flex: 1;

  .step {
    @include common.mn-font(#ddfb6e, 14px, 900, 20px, -0.03em);
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  .title {
    @include common.mn-font(white, 30px, 900, 38px, -0.03em);
  }

  .description {
    @include common.mn-font(white, 16px, 500, 24px);
    margin-top: 12px;
  }

  .form {
    margin-top: 24px;
    min-height: 468px;
    padding: 32px 110px;
    border: 1px solid #292a2e;
    border-radius: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
