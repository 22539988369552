@use 'src/styles/common';

.success {
  display: flex;
  padding: 180px 90px 90px;
  max-width: 1440px;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;

  .tokenInfo {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1;

    .tokenImage {
      height: 300px;
      width: 300px;
      border: 1px solid #292a2e;
    }

    .collectionName {
      @include common.mn-font(white, 16px, 700, 24px);
    }

    .by {
      display: flex;
      gap: 10px;
      align-items: center;

      > div {
        @include common.mn-font(#818181, 14px, 900, 24px);
      }
    }

    .title {
      @include common.mn-font(white, 12px, 400, 24px);
    }

    .benefits {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      .benefit {
        background: #1d1e20;
        border: 1px solid #292a2e;
        border-radius: 16px;
        padding: 8px;
        display: flex;
        flex-direction: column;

        .name {
          @include common.mn-font(white, 16px, 700, 20px);
        }

        .description {
          @include common.mn-font(white, 12px, 500, 18px);
        }
      }
    }
  }

  .status {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    flex: 1;
    gap: 20px;
  }
}
