@use 'src/styles/common';

.token {
  width: 100%;
  padding-top: 90px;
  padding-bottom: 90px;
  display: flex;
  flex-direction: column;

  .backContainer {
    padding: 25px;
    border-bottom: 1px solid #303136;
  }

  .upperContainer {
    min-width: 100%;
    max-width: common.$var-container-width;
    gap: 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 90px;

    .image {
      height: 450px;
      width: 450px;
    }

    .tokenInfo {
      display: flex;
      flex-direction: column;

      .label {
        @include common.mn-font(#cacbce, 14px, 400, 28px);
      }

      > div:first-child {
        border-bottom: 1px solid #303136;
        padding-bottom: 20px;
        gap: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .nameContainer {
          @include common.mn-font(white, 25px, 700, 48px);
          display: flex;
          gap: 10px;
        }

        .collection .collectionName {
          @include common.mn-font(white, 18px, 400, 28px);
          cursor: pointer;
        }

        .users {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;

          > div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        }
      }

      .actions {
        padding-top: 10px;

        .priceContainer {
          .price {
            @include common.mn-font(white, 30px, 600, 38px);
            background: linear-gradient(
                271.24deg,
                #a0d42f 42.52%,
                #ddfb6e 66.95%,
                #aadb3a 80.7%,
                #d5fc83 89.69%,
                #aadb3a 98.58%
              ),
              linear-gradient(275.18deg, #df6efb 37.91%, #ff08ab 98%),
              linear-gradient(0deg, #ffffff, #ffffff);

            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }

  .tabs {
    padding: 64px;
    max-width: common.$var-container-width;
    min-width: 0;
    margin: auto;

    .information {
      .header {
        padding: 40px 0 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
        @include common.mn-font(white, 30px, 700, 38px);
      }
    }
  }
}

@media (max-width: common.$var-breakpoint-sm) {
  .token {
    .upperContainer {
      padding: 10%;

      .image {
        height: auto;
        width: 100%;
      }

      .tokenInfo {
        width: 100%;
      }
    }

    .tabs {
      padding: 60px 0 0;

      .information .header {
        padding: 40px 10% 20px;
      }
    }
  }
}
