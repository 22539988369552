@use "src/styles/common";

.request {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  max-width: 440px;
  background: common.fn-color(primary);
  padding: 30px;

  .avatar {
    margin-bottom: 15px;
  }

  .fullName,
  .address,
  .profileLink {
    @include common.mn-font(black, 14px, 600, 150%, 0.01em);
  }

  .fullName {
    margin-bottom: 10px;
  }

  .address,
  .profileLink {
    display: flex;
    align-items: center;

    > svg {
      margin-left: 4px;
    }
  }

  .email,
  .userName {
    margin-bottom: 5px;
  }

  .socialLinks,
  .address,
  .profileLink {
    margin-bottom: 25px;
  }

  .userName {
    @include common.mn-font(black, 20px, 700, 120%, 0.02em);
  }

  .email,
  .socialLinks .link {
    @include common.mn-font(black, 12px, 400, 150%);
  }

  .socialLinks {
    display: flex;
    gap: 10px;
  }

  .buttons {
    width: 100%;
    display: flex;
    gap: 15px;
    flex-direction: column;
    margin-top: 15px;

    .button {
      width: 100%;
    }
  }
}
