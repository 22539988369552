@use 'src/styles/common';

.appButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background: linear-gradient(314.99deg, #a0d52f 1.99%, #ddfb6e 98%);
  border-radius: 12px;
  height: 40px;

  @include common.mn-font(black, 16px, 900, 100%);

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.round {
    border-radius: 24px;
  }

  &.white {
    background: common.fn-color(white);
  }

  &.outline {
    background: none;
    color: white;
    border: 1px solid #303136;
  }

  &.dark {
    background: rgba(255, 255, 255, 0.15);
    color: white;

    &.inactive {
      background: transparent;
      border: 1px solid #292a2e;

      &:not(:hover) {
        color: #818181;
      }
    }
  }

  &.dark2 {
    background: #292a2e;
    color: white;
  }
}
