@use 'src/styles/common';

.bonusPreview {
  display: flex;
  padding: 16px;

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:nth-child(1) {
      flex: 2.5;
      display: flex;
      gap: 12px;
      @include common.mn-font(white, 14px, 500, 20px);

      .nameBlock {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .name {
          @include common.mn-font(white, 14px, 500, 20px);
        }

        .status {
          @include common.mn-font(#cacbce, 14px, 400, 20px);
        }
      }
    }

    &:nth-child(2) {
      flex: 1.5;
      justify-self: flex-start;
    }

    &:nth-child(3) {
      flex: 0.5;

      .buttons {
        display: flex;
        gap: 28px;

        .icon {
          height: 20px;
          width: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
