@use 'src/styles/common';

.bonusPreview {
  display: flex;
  padding: 20px;
  gap: 32px;
  background: #ffffff;
  border-radius: 16px;

  &.compact {
    .disclaimer {
      display: none;
    }
  }

  .info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
      display: flex;
      gap: 10px;
      align-items: center;

      .name {
        @include common.mn-font(black, 20px, 900, 30px);
      }

      .iconPlay {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg path {
          fill: black;
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      gap: 8px;

      div:first-child {
        @include common.mn-font(black, 20px, 900, 30px);
      }

      div:nth-child(2) {
        @include common.mn-font(#333333, 12px, 500, 18px);
      }
    }
  }

  .preview {
    height: 200px;
    width: 134px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    img {
      height: 100%;
      width: 100%;
    }

    .buttonCollect {
      cursor: pointer;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 118px;
      height: 33px;
      border-radius: 18px;
      @include common.mn-font(black, 12px, 600, 18px);
      gap: 10px;

      svg path {
        fill: black;
      }
    }
  }
}
