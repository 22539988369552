@use 'src/styles/common';

.appTabs {
  width: 100%;

  .header {
    border-bottom-color: transparent;
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow-x: auto;
    padding-bottom: 2px;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  .panel {
    border-top: 1px solid common.fn-color(dark, 6);
  }

  .tab {
    @include common.mn-font(dark, 16px, 600, 24px, '', 0);
    padding: 19px 24px 20px;

    &:hover {
      background: none;

      &[aria-selected='false'] {
        color: common.fn-color(white);
        border: none;
      }
    }

    &[aria-selected='true'] {
      color: common.fn-color(lime);
      border-color: common.fn-color(lime);
      border-width: 2px;
    }
  }

  @media (max-width: common.$var-breakpoint-md) {
    .tab {
      padding: 16px 12px;
    }
  }
}
