@use '../../../styles/common';

.bonusForm {
  form {
    min-height: 650px;
    justify-content: flex-start;
  }

  .withMargin {
    margin-bottom: 24px;
  }

  .contentDescription {
    @include common.mn-font(#cacbce, 14px, 500, 20px);
    margin-bottom: 16px;
  }

  .selectContainer {
    display: flex;
    gap: 16px;

    .select {
      width: 136px;
    }

    .noneDescription {
      @include common.mn-font(#cacbce, 14px, 500, 20px);
    }
  }
}
