@use 'src/styles/common';

.appBadge {
  display: flex;
  align-items: center;
  padding: 3px 6px;
  background: linear-gradient(
    314.99deg,
    rgba(160, 213, 47, 0.15) 1.99%,
    rgba(221, 251, 110, 0.15) 98%
  );
  border-radius: 6px;
  height: 26px;
  @include common.mn-font(#ddfb6e, 14px, 500, 20px);
  text-transform: capitalize;

  &.draft {
    background: white;
    color: black;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.gray {
    color: black;
    background: #818181;
  }
}
