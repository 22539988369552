@use '../../styles/common';

.player {
  position: relative;
  width: 100%;
  padding-top: 50%;
  border-radius: 24px;

  &.hideControls {
    .controls,
    .information {
      visibility: hidden;
      opacity: 0;
      transition: opacity 300ms, visibility 300ms;
    }

    &.fullscreen {
      cursor: none;
    }
  }

  .information {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px;

    .title {
      @include common.mn-font(white, 30px, 700, 38px);
    }

    .details {
      display: flex;
      gap: 16px;

      .author {
        @include common.mn-font(white, 16px, 600, 24px);
      }

      .brief {
        @include common.mn-font(white, 16px, 400, 24px);
      }
    }
  }

  .controls {
    position: absolute;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 0 16px 16px;
    width: 100%;
    gap: 14px;

    .button {
      cursor: pointer;
    }

    .rightBlock {
      position: relative;
      flex: 1;
      display: flex;
      align-items: center;
      gap: 16px;
      border-radius: 6px;

      .time {
        @include common.mn-font(white, 12px, 400, 18px);
      }

      .progressContainer {
        flex: 1;
        height: 6px;
        overflow: hidden;
        position: relative;
        margin-left: 44px;
        background: rgba(104, 104, 104, 0.8);
        border-radius: 23px;

        .progress {
          height: 100%;
          background: linear-gradient(314.99deg, #a0d52f 1.99%, #ddfb6e 98%),
            linear-gradient(0deg, #ddfb6e, #ddfb6e), #d9d9d9;
        }

        .buttonProgress {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
        }
      }

      .buttonFullScreen {
        height: 20px;
        width: 20px;
      }
    }

    .buttonPlay {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 76px;
      height: 40px;

      background: linear-gradient(314.99deg, #a0d52f 1.99%, #ddfb6e 98%);
      border-radius: 6px;

      .iconToggle path {
        fill: black;
        stroke: black;
      }
    }
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
