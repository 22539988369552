@use '../../styles/common';

.roadMap {
  max-width: 745px;

  .title {
    @include common.mn-font(white, 16px, 600, 24px, 0.02em);

    &.done {
      text-decoration: line-through;
    }
  }

  .addTaskButton {
    width: 100%;
    margin-top: 20px;
  }

  .subtask {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    .content {
      flex: 1;
    }

    .subtitle {
      @include common.mn-font(#cacbce, 16px, 700, 20px);
    }

    .description {
      @include common.mn-font(#cacbce, 14px, 400, 24px, 0.02em);
    }
  }
}
