@use 'src/styles/common';

.bonusTypeLabel {
  padding: 5px 6px;
  background: #292a2e;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  @include common.mn-font(white, 12px, 500, 18px);
}
