@use 'src/styles/common';

.switchNetworkModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 0 32px 20px;
  @include common.mn-font(white, 12px, 500, 18px);

  .actions {
    display: flex;
    gap: 20px;
    align-self: flex-start;
    justify-content: flex-start;

    .button {
      max-width: 200px;
    }
  }
}
