@use 'src/styles/common';

.appImageUploader {
  display: flex;
  gap: 20px;

  .uploader {
    flex: 1;
  }
}
