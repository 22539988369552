@use '../../../styles/common';

.optionForm {
  form {
    min-height: 650px;
    justify-content: flex-start;

    .row {
      display: flex;
      gap: 20px;

      > div {
        flex: 1;
      }
    }

    .withMargin {
      margin-bottom: 24px;
    }

    .imageDescription {
      @include common.mn-font(#cacbce, 14px, 700, 20px);
      margin-bottom: 16px;
    }

    .fieldDescription {
      margin-top: 4px;
      @include common.mn-font(#cacbce, 12px, 500, 18px); // :TODO
    }
  }

  .contentDescription {
    @include common.mn-font(#cacbce, 14px, 500, 20px);
    margin-bottom: 16px;
  }

  .selectContainer {
    display: flex;
    gap: 16px;

    .select {
      width: 136px;
    }

    .noneDescription {
      @include common.mn-font(#cacbce, 14px, 500, 20px);
    }
  }
}
