.optionLinks {
  display: flex;
  flex-direction: column;
  flex: 1;

  .linkContainer {
    display: flex;
    gap: 12px;

    .input {
      flex: 1;
    }

    .removeButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      cursor: pointer;

      background: rgba(241, 80, 80, 0.15);
      border-radius: 8px;

      svg path {
        fill: #f15050;
      }
    }
  }

  .withMargin {
    margin-bottom: 16px;
  }

  .button {
    align-self: flex-start;
  }
}
