.appSkeleton {
  height: 100%;
  width: 100%;

  &:before {
    background: rgba(#1d1e20, 0.9);
  }

  &:after {
    background: #1d1e20;
  }
}
