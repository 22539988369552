@use '../../styles/common';

.mediaGallery {
  width: 100%;
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  container-type: inline-size;
  container-name: gallery;

  .viewContainer {
    margin-bottom: 25px;
    position: relative;

    .mediaContainer {
      height: 100%;
      width: 100%;
      border-radius: 20px;
      overflow: hidden;
      background: black;

      .imageContainer {
        width: 100%;
        border-radius: 20px;
        overflow: hidden;
        background: black;
        padding-top: 50%;
        position: relative;

        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .control {
      position: absolute;
      top: 50%;
      z-index: 1;
      display: flex;
      width: 32px;
      height: 54px;
      justify-content: center;
      align-items: center;
      background: #292a2e;
      border-radius: 6px;
      color: white;

      &.left {
        left: 0;
        transform: translate(-30%, -50%);
      }

      &.right {
        right: 0;
        transform: translate(30%, -50%);
      }
    }
  }

  .carousel {
    border-radius: 12px;
  }

  .slide {
    border-radius: 12px;
    padding: 2px;
    border: 3px solid transparent;
    position: relative;
    cursor: pointer;

    .playIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 20px;
      width: 20px;
      transform: translate(-50%, -50%);
    }

    &.selected {
      border-color: common.fn-color(lime);
    }
  }

  .thumbnail {
    border-radius: 12px;
    object-fit: cover;
    width: 162.8px;
    height: 91.57px;
  }

  @container gallery (max-width: #{common.$var-breakpoint-sm}) {
    .viewContainer .control {
      height: 36px;
      width: 24px;
    }

    .carousel {
      .slide {
        width: 82px;
        height: 82px;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  @container gallery (max-width: #{common.$var-breakpoint-md}) {
    .viewContainer {
      margin-bottom: 20px;
    }
  }
}
