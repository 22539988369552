@use 'src/styles/common';

//@font-face {
//  font-family: Inter, sans-serif;
//  src: local('Inter'), url('fonts/Inter-Bold.ttf') format('truetype');
//}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #0c0c0e;
}

button,
textarea,
input,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

fieldset {
  padding: 0;
  border: 0;
  margin: 0;
}

::selection {
  background: lightgray;
}
