.profileSocialMedia {
  background: #292a2e;
  border: 1px solid #3b3c3f;
  border-radius: 12px;
  gap: 12px;
  padding: 8px;
  display: flex;
  align-items: center;

  .icon {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
}
