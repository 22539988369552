@use 'src/styles/common';

.confirmationModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .header {
    @include common.mn-font(white, 20px, 700, 30px);
  }

  .text {
    @include common.mn-font(white, 12px, 500, 18px);
  }

  .actions {
    display: flex;
    gap: 12px;
    align-self: flex-start;
    justify-content: flex-start;
    width: 100%;

    .button {
      flex: 1;
    }
  }
}
