.appFileUploader {
  height: fit-content;
  flex: 1;

  .files {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .inputHidden {
    display: none;
  }

  .files + .input {
    margin-top: 16px;
  }
}
