@use 'src/styles/common';

.collectionBonuses {
  container-type: inline-size;
  container-name: bonuses;
  border-radius: 30px;
  border: 1px solid common.fn-color(dark, 6);
  padding: 24px;

  .video,
  .preview {
    margin-bottom: 20px;
  }

  .bonuses {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 20px;
    grid-auto-flow: row;

    .bonus {
      background: #1d1e20;
      border-radius: 16px;
    }
  }

  .carousel {
    display: none;
  }

  @container bonuses (max-width: calc(#{common.$var-breakpoint-md - 80px})) {
    .bonuses {
      display: none;
    }

    .preview {
      display: none;
    }

    .carousel {
      display: block;

      .previewMobile {
        height: 100%;
      }
    }
  }
}
