@use 'src/styles/common';

.bonusContent {
  display: flex;
  gap: 12px;
  align-items: center;

  .value {
    background: #1d1e20;
    border: 1px solid #303136;
    border-radius: 12px;
    flex: 1;
    padding: 10px 16px;
    @include common.mn-font(white, 16px, 500, 24px);
  }
}
